import { CookieStore } from '../services/cookieStore';
import GA from '../data/GA';

// const EXPERIMENTS_COOKIE_NAME = 'wcfexp';
const EXPERIMENT_SEPARATOR = '||';
const VARIANT_SEPARATOR = '::';

interface Experiment {
  name: string;
  variants: string[];
}

export const EXPERIMENTS: { [key: string]: Experiment } = {
  singlePage: {
    name: 'singlePageSubmissionV6',
    variants: ['control_single_page_submission_v6', 'var_1_single_page_submission_v6'],
  },
};

function getExperimentCookieValue(experimentName: string) {
  // Cookie format: _wor_opt_exp_=experiment1±variant1§experiment2±variant2§experiment3±variant3
  const experimentsCookie: string = CookieStore.getExperiments();
  const experimentStrings = experimentsCookie.split(EXPERIMENT_SEPARATOR);
  const experiments = experimentStrings.map((experimentString: string) =>
    experimentString.split(VARIANT_SEPARATOR),
  );
  const exp = experiments.find((experiment) => experiment[0] === experimentName);
  return exp ? exp[1] : undefined;
}

export function getExperiment(
  experimentName: string,
  defaultValue: string | undefined = undefined,
) {
  console.debug(`experiment ${experimentName} defaultValue: `, defaultValue);
  const experimentValue = getExperimentCookieValue(experimentName) || defaultValue;

  console.debug(`experiment ${experimentName} returning: `, experimentValue);
  return experimentValue;
}

export const getVariant = (experiment: Experiment) => {
  const variant = getExperimentCookieValue(experiment.name);
  if (!variant) {
    return '';
  }
  const variantIndex = experiment.variants.indexOf(variant);
  if (variantIndex === -1) {
    console.log(variant, 'is not a valid variant for experiment', experiment.name);
    return '';
  }
  return `${variantIndex}`;
};

export const variantIsLoaded = (experiment: Experiment, variantIndexes: string[]) => {
  const variantFromCookie = getVariant(experiment);
  const variantIndex = variantIndexes.indexOf(variantFromCookie);
  if (variantIndex === -1) {
    console.log(`variants:${experiment.variants} loaded without cookie variant`);
    return;
  }
  GA.experimentEvent(experiment.name, experiment.variants[+variantFromCookie]);
};

// export function getBooleanExperiment(
//   experimentName: string,
//   defaultValue: string | undefined = undefined,
// ) {
//   const experiment = getExperiment(experimentName, defaultValue);
//   return !!(experiment && experiment !== '0');
// }

// export function getNumericExperiment(
//   experimentName: string,
//   defaultValue: number | undefined = undefined,
// ) {
//   const experiment = getExperiment(experimentName, (defaultValue || 0).toString());
//   console.log('experiment', experiment);
//   return Number(experiment) || 0;
// }

export function isPromoExperiment() {
  return getExperiment('sitewide_promotion') === 'var_1_promotion_banner';
}
